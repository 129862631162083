@import '../../../styles/variables.less';

.api-credentials-managment {
  width: 100%;
  &-wrapper {
    border-radius: @card-border-radius;
    background-color: #fff;
    padding: 20px 20px 17px;
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
      h3 {
        font-size: 16px;
        font-weight: 500;
        color: @black-color;
      }
      .ant-btn {
        width: 196px;
        height: 39px;
        font-size: 16px;
      }
    }
    &-table {
      .ant-table-thead > tr > th {
        height: 64px;
        background-color: #f7f9fa;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      }
      .ant-table-tbody > tr > td {
        background: rgba(64, 113, 217, 0.06);
        height: 125px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      }
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }
    }
  }
}
