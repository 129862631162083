.copied{
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  transition: display 0.2s;
  width: 90px;
  height: 27.1px;
  padding-top: 5px;
  border-radius: 13.5px;
  box-shadow: 0 2px 9px 0 rgba(0, 21, 41, 0.08);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  font-size: 10px;
  display: none;
  &.show{
    display: block;
  }
}