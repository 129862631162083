@import '../../../styles/variables.less';

.topup-history{
  width: 100%;
  &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
    .topup-details{
      width: 274px;
      height: 107.9px;
      background-color: @slate-blue;
      border-radius: @card-border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      position: relative;
        .icon{
          padding-right: 15px;
          font-size: 50px;
        }
        &-card-content {
          padding-left: 5px;
          .reports {
            font-size: 18px;
            margin: 0;
          }
          .detailed {
            font-size: 14px;
            font-weight: 300;
          }
        }
        &:after{
        content:'';
        position: absolute;
        top: 106px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: 10px solid @slate-blue;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        }
    }
   .ant-btn{
    width: 141.5px;
    height: 34px;
    font-size: 18px;
    padding: 0;
   }
  }
  &-table{
    width: 100%;
    &-wrapper{
      border-radius: @card-border-radius;
      background-color: @white-color;
      padding: 20px 20px 17px;
      
      .topup-page-iframe {
        border-radius: @card-border-radius;
        border: none;
        min-height: 400px;
      }
    }
  }
}