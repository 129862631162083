@import '../../../styles/variables.less';

.order-summary {
  background-color: @white-color;
  box-shadow: @card-shadow;
  padding: 20px 15px 22.1px;
  border-radius: @card-border-radius;
  h3 {
    font-size: 16px;
  }
  &-payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    line-height: 1.6;
    &-description {
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        color: @black-color;
        padding-bottom: 8px;
      }
    }
    &-amount {
      display: flex;
      flex-direction: column;
      padding-bottom: 8px;
      text-align: right;
      span {
        font-size: 16px;
        color: @black-color;
        font-weight: 500;
      }
      span:nth-child(1) {
        color: @slate-blue;
      }
    }
  }
  &-total {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: -5px;
    span {
      font-size: 16px;
    }
    span:nth-child(2) {
      font-weight: 500;
    }
  }
  .inclusive-vat {
    font-size: 14px;
    font-weight: 300;
  }
  &-button-content {
    margin-top: 20px;
    button {
      height: 40px;
      background-color: @slate-blue;
      color: @white-color;
      box-shadow: 0 14px 30px 0 rgba(108, 110, 240, 0.2);
    }
  }
  &-security-details {
    background-color: @lighter-grey;
    min-height: 55px;
    border-radius: @card-border-radius;
    margin-top: 10px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      padding-top: 5px;
      font-size: 12px;
      font-weight: 300;
      span {
        color: @slate-blue;
      }
      span:nth-child(1) {
        font-weight: normal;
        color: @black-color;
      }
    }
  }
  &-payment-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    span {
      font-size: 14px;
      font-weight: 300;
    }
    .payment-icons {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      img {
        width: 46.2px;
        height: 29.9px;
      }
    }
  }
}
