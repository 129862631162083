@import '../../../styles/variables.less';

.bankdetails{
  width: 100%;
  &-first-section{
    display: flex;
    .bank-name{
      margin-left: 20px;
    }
  }
  &-second-section{
    display: flex;
    .city-name{
      margin-left: 20px;
    }
  }
}