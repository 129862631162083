@import "../../styles/variables.less";

.app-container.settings {
  margin: 77px 64px 0 56px;
  min-height: calc(100vh - 318px);
}

.profile-settings-tab {
  margin-top: -3px;

  .ant-tabs {
    .ant-tabs-ink-bar {
      background: #fabb05;
      height: 3px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @black-color;
      font-size: 16px;
      font-weight: 500;
    }

    .ant-tabs-tab-btn {
      font-size: 16px;
      font-weight: normal;
      &:hover {
        color: @black-color;
      }
    }

    .ant-tabs-nav .ant-tabs-nav-operations {
      display: none;
    }
  }
}
