@import '../../styles/variables.less';

.app-container.orders {
  margin: 19px 64px 0 56px;  
  min-height: calc(100vh - 260px);

  .orders-page {
    width: 100%;
    margin-bottom: 10px;
    &-wrapper {
      border-radius: @card-border-radius;
      background-color: #fff;
      padding: 20px 20px 17px;
      height: 600px;
      .order-page-iframe {
        border-radius: @card-border-radius;
        border: none;
      }
    }
  }
}