@import '../../../styles/variables.less';

.home-header {
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 62px;
  padding: 27px 64px 0 56px;

  // box-shadow: @header-shadow;
  background-color:#f5f7fa;
  &-heading {
    // margin-left: 20px;

    .company-name {
      // padding-top: 25px;
      color: @black-color;
      font-size: 24px;
    }

    .page-name {
      // padding-top: 19px;
      color: @black-color;
      font-size: 24px;
      // height: 17px;
      font-weight: 500;
    }
    
  }
}

.content-box{
  margin-top: 5px;
 background-color: #f0f1ff;
  width: 288px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  font-size: 12px;
  gap: 8px;

  img{
    width: 14px;
    height: 14px;
  }
}