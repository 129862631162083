@import '../../../styles/variables.less';

.profile {
  width: 666px;
  height: 245px;
  margin: 22px 514px 293px 20px;
  padding: 24px 19px 29px 20px;
  border-radius: 12px;
  background-color: @white-color;
  
  &-first-section {
    display: flex;
    margin-bottom: 10px;
    .last-name {
      margin-left: 21px;
    }
  }
  &-second-section {
    display: flex;
    .receiver-mobile {
      margin-left: 21px;
    }
  }
  button {
    width: 107px;
    height: 40px;
  }
  .ant-form-item-explain-error {
    font-size: 10px;
  }
}
