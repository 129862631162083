@import '../../../styles/variables.less';

.chequedetails{
  &-first-section{
    display: flex;
    .payment-date{
      margin-left: 20px;
    }
  }
  &-second-section{
    display: flex;
    .person-name{
      margin-left: 20px;
    }
  }
}