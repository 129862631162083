@import '../../styles/variables.less';

.app-container.resend {
  margin: 19px 64px 0 56px;
  min-height: calc(100vh - 260px);

  .resend-container {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px;
    &-header {
      display: flex;
      justify-content: space-between;
      &-form {
        h3 {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
        }
        img {
          width: 16px;
          height: 16px;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
        }
        .resent-input-form-item {
          display: flex;
          align-items: baseline;
          .ant-input-group {
            display: flex;

            .ant-input {
              background-color: @white-color;
              border-width: 0 0 1px 0;
              border-radius: 0;
              font-size: 14px;
              width: 352px;
              &:focus,
              &:hover {
                box-shadow: none;
                border-right-width: 0;
              }
            }
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background-color: #fff;
            box-shadow: none;
            border: none;
            border-bottom: 1px solid #f5f5f5;
            border-radius: 0;

            &:hover{
              border-bottom: 1px solid @slate-blue;
            }
            &::after{
              content: none;
            }
          }
        }
      }
      &-button {
        button {
          width: 127.5px;
          height: 34px;
          line-height: normal;
          font-size: 18px;
        }
      }
    }
    &-table {
      height: 200px;
      .resend-iframe{
        border: none;
        border-radius: @card-border-radius;
      }
    }
  }
}
