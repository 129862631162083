@import '../../../styles/variables.less';

.float-label {
  position: relative;
  width: 254px;

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
}

  .ant-input-affix-wrapper {
    border: solid 1px @form-border;
    .ant-input:not(:first-child) {
      padding-left: 45px;
    }
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 35px;
}
  &.float-select {
    .ant-select-lg.higlighted .ant-select-selector {
      height: 50px !important;
    }

    .ant-select-selection-item {
      font-weight: 500;
      font-size: 16px;
    }

    .ant-select-lg .ant-select-selector {
      height: 50px;
      border-color: @form-border;
      align-items: center;
      border-radius: 4px;
      &:hover {
        border-color: @form-border !important;
      }
    }

    .ant-select-lg .ant-select-selection__rendered {
      line-height: 48px;
    }
  }

  .ant-input {
    border-color: @form-border;
    background-color: @white-color;
    height: 50px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
  }

  .ant-input[disabled] {
    background-color: #fff;
  }

  .higlighted.ant-input {
    border-color: @form-border;
  }
  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 18px;
    transition: 0.4s ease all;
    color: #707070;

    &.as-placeholder {
      line-height: normal;
    }

    &.as-label {
      top: -7px;
      font-size: 12px !important;
      background: white;
      padding: 0 4px;
      margin-left: -4px;
      line-height: normal;
      z-index: 1;
    }
  }
  .float-label-active{
      color: #707070;
      font-weight: 500;
      font-size: 16px;
  }
}

.ant-input:placeholder-shown {
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
}

.float-label-width.float-label{
  width: 303px;
  .ant-input{
    font-weight: normal;
    font-size: 16px;
  }
}