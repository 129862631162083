@import '../../../styles/variables.less';

.change-password {
  width: 666px;
  height: 245px;
  margin: 22px 514px 293px 20px;
  padding: 24px 19px 29px 20px;
  border-radius: 12px;
  background-color: @white-color;
  .ant-input-affix-wrapper {
    width: 303px;
    height: 50px;
    border: solid 1px @form-border;
  }
  .ant-input, .ant-input-password {
    background-color: #fff;
    font-size: 14px;
    border-width: 0.5px;
}
  &-first-section{
    margin-bottom: 30px;
  }
  &-second-section {
    display: flex;
    .confirm-password {
      margin-left: 21px;
    }
  }
  button {
    width: 156px;
    height: 40px;
  }
  .ant-form-item-explain-error {
    font-size: 10px;
  }
}
