@import '../../styles/variables.less';

.app-container.brand {
    margin: 19px 64px 0 56px;
    min-height: calc(100vh - 260px);

    .brands-page {
        width: 100%;
        margin-bottom: 10px;

        iframe {
            border: none;
        }

        &-wrapper {
            border-radius: @card-border-radius;
            background-color: #fff;
            padding: 20px 20px 17px;
            height: 600px;

            .brands-page-iframe {
                border-radius: @card-border-radius;
                border: none;
            }
        }
    }
}