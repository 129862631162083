@import '../../../styles/variables.less';

.topup-payment-form {
  background-color: @white-color;
  box-shadow: @card-shadow;
  border-radius: @card-border-radius;
  padding: 20px 20px 25px;
  height: auto;
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
    a {
      font-weight: 500;
      font-size: 16px;
      color: #4446d3;
    }
  }
  &-container {
    .ant-form-item-explain-error {
      width: 254px;
  }
    .form-first-section {
      display: flex;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 20, 40, 0.15);
      .topup-amount {
        margin-left: 20px;
        .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border: none;
          box-shadow: none;
      }
        .input{
          width: 254px;
        }
        .ant-input-group-addon:first-child {
          border-right: 0;
          height: 50px;
      }
        .ant-input{
          height: 50px;
          background-color: #fff;
        }
      }
    }
    .form-payment-mode {
      padding-top: 30px;
      .form-second-section {
        padding-top: 16px;
        display: flex;
        .selector {
          margin-left: 20px;
        }
      }
    }
  }
  &-switch{
    span{
      padding-left: 10px;
      font-size: 16px;
    }
    .ant-switch-checked {
      background: @green-color;
  }
  }
}
