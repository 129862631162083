@import '../../../styles/variables.less';
.create-api {
  display: flex;
  justify-content: center;
  align-items: center;

  &-card {
    width: 100%;
    max-width: 568px;
    border-radius: @card-border-radius;
    overflow: hidden;
    box-shadow: @card-shadow;
    &-header {
      text-align: center;
      margin-bottom: 14px;
      span {
        font-size: 24px;
        font-weight: 500;
        color: @slate-blue;
      }
    }
    &-message {
      text-align: center;
      margin-bottom: 26px;
      border-bottom: 1px solid rgba(0, 20, 40, 0.15);
      width: 500px;
      p {
        font-size: 16px;
        font-weight: normal;
        color: @black-color;
      }
    }
    &-date {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 24px;
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
    
    .copy-img {
      width: 10px;
      height: 10px;
      user-select: none;
      object-fit: contain;
      cursor: pointer;
    }
    &-key {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      .api-key-name {
        margin-bottom: 10px;
      }
      .api-key-data {
        display: flex;
        align-items: center;
        padding: 4px 15px 4.1px 16px;
        background-color: #ededff;
        border-radius: 13.5px;
        position: relative;
        span {
          font-size: 16px;
          font-weight: 500;
          color: #6c6ef0;
          padding-right: 10px;
        }
      }
    }

    &-highlight {
      text-align: center;
      span {
        font-weight: bold;
        color: #f44242;
      }
    }
  }
}
