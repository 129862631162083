@import '../../../styles/variables.less';

.ant-layout-footer{
    height: 88px;
    background: @white-color !important;
    border-top: solid 1px #e6eff5;
    text-align: center;
    padding-top: 33.7px !important;
    padding-left: 96px !important;
    font-size: @body-font-size;
    line-height: normal;
    letter-spacing: normal;
    a{
        color: @slate-blue;
    }

}