@import '../../styles/variables.less';

.public-layout{
    .ant-layout-header{
        background: @white-color;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.08);

        h1{
            text-align: center;
            margin-bottom: 0;
            font-size: 18px;
        }
    }
    &--logo{
        width: 74.8px;
        height: 50px;
    }
    
    .ant-layout-content{
        min-height: calc(100vh - 150px);
    }

    .ant-layout-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}