@import './antd.less';
@import './variables.less';

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.ant-btn {
    font-size: @body-font-size;
    box-shadow: @button-shadow;
}
.ant-btn.ant-btn-block {
    border: none
}
.ant-input,
.ant-input-password {
    background-color: @light-grey;
    font-size: @body-font-size;
    border-width: 0.5px;

    &.ant-input-lg.custom-input,
    &.ant-input-password-large.custom-input {
        min-height: 40px;
    }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: @light-grey;
}

.modal-dialogs{
    .ant-modal-confirm-body .ant-modal-confirm-title {
        color: #000;
        font-size: 18px;   
    }

    svg{
        width: 16px;
        height: 16px;
        margin-top: 3px;
    }

    .ant-modal-confirm-body > .anticon {
        margin-right: 10px;
        margin-left: 11px;
    }
    
    .ant-modal-content {
        height: auto;
        border-radius: @card-border-radius;
    }
    
     .ant-modal-body {
        padding: 24px 32px 24px;
    }
    
     .ant-modal-confirm-btns {
        margin-top: 13px;
    }
    
    .ant-btn {
        width: 80px;
        height: 34px;
        background-color: @slate-blue;
    }

}

.ant-message-notice-content {
    background: #000;
    color: #fff;
}

.table-pagination-total {
    color: @grey-color;
    margin-right: 10px;
}

.ant-layout-content{
    padding-left: 96px;
}

.ant-layout{
  background-color:#f5f7fa;
}

.neterror{
  margin: 0;
}

.normal-line-height {
    line-height: normal;;
}