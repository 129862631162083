@import '../../styles/variables.less';

.app-container.topup {
  margin: 19px 64px 0 56px;
  min-height: calc(100vh - 260px);
  
  .top-up{
    width: 100%;
    margin-bottom: 30px;
  }
}